import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { Button, Form, InputGroup } from 'react-bootstrap'

// TODO: Have search submit on enter press.
const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const searchIndex = searchClient.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)
  
const openScript = searchQuery => () => {
  searchIndex.search(searchQuery, {
      attributesToRetrieve: ["name", "url"],
  }).then(({ hits }) => {
    if (hits === undefined || hits.length === 0) {
      window.location.href = "/no-tool-hit"
    } else {
      console.log(hits)
      switch (hits[0].name) {
        case "Split Spreadsheet":
          window.location.href = "/tools/split-spreadsheets"
          break
        case "Merge Excel Files":
          window.location.href = "/tools/merge-spreadsheets"
          break
        case "Shuffle Spreadsheet Rows":
          window.location.href = "/tools/shuffle-rows"
          break
        case "Hash Files":
          window.location.href = "/tools/hash-files"
          break
        case "Append Spreadsheet":
          window.location.href = "/tools/append-spreadsheets"
          break
        default:
          window.location.href="/no-tool-hit"
          break
      }
    }
  })
}

function SearchBox() {
    // Introduce state for setting and extracting the searchbar query.
    const [query, setQuery] = useState("")
    return (
      <Form style={{width:"50%", minWidth:"320px", margin:"auto", paddingTop:"16px", paddingBottom:"16px"}}>
        <InputGroup>
          <Form.Control type="text" id="searchbox" placeholder="Describe your task e.g., 'split spreadsheet'" onInput={e => setQuery(e.target.value)}/>
          <Button variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} onClick={openScript(query)}>Search</Button>
        </InputGroup>
      </Form>
    )
}

export default SearchBox